<template>
    <AModal
        :visible="visible"
        title="List Event"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <div
            class="row justify-content-end">
            <div class="col-auto">
                <AButton
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnEventPayload">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #rangedate="{ record }">
                    <span>{{`${record.start_date} s/d ${record.end_date}`}}</span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnEventPayload(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- create or update -->
        <DetailEventCreateOrUpdate
            v-if="visibleEventPayloadModal"
            :program_id="item.id"
            v-model:visible="visibleEventPayloadModal"
            v-model:item="visibleEventPayloadItemModal"
            @success="fetchDataList"/>

    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import DetailEventCreateOrUpdate from './DetailEventCreateOrUpdate'
import { pickBy } from 'lodash'

export default defineComponent({
    components: {
        DetailEventCreateOrUpdate,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nama Event',
                    dataIndex: 'event_name',
                },
                {
                    title: 'Tipe Event',
                    dataIndex: 'event_type_value',
                },
                {
                    title: 'Tambahan Poin',
                    dataIndex: 'operation_value',
                },
                {
                    title: 'Masa Berlaku',
                    slots: { customRender: 'rangedate' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: `/api/loyalty-program-events
`,
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                program: props.item.id,
                page: 1,
                "per-page": 10,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isDelete = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`${state.endpoint}/${record.id}`)
                        .then(({ data }) => {
                            fetchDataList()
                            message.success('Berhasil di hapus!')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle event
        const visibleEventPayloadModal = ref(false);
        const visibleEventPayloadItemModal = ref(null);

        const btnEventPayload = (item = null) => {
            visibleEventPayloadModal.value = true
            visibleEventPayloadItemModal.value = item
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            handleModalCancel,
            handleTableChange,
            btnDelete,
            visibleEventPayloadModal,
            visibleEventPayloadItemModal,
            btnEventPayload,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
