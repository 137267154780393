<template>
    <AModal
        :visible="visible"
        title="List User Pic"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">

        <AForm
            v-if="state.isForm"
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Pilih User Pic"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="user_id"
                        has-feedback>
                        <FilterLoyaltyUserPic
                            v-model:value="form.user_id"
                            role_id="747552"
                            nopeg_status="0"
                            :mode="null"/>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row class="form-row mt-2">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <AButton
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">Simpan</AButton>
                        <AButton
                            title="Batal"
                            @click="state.isForm = !state.isForm; form.reset()">Batal</AButton>
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>
        <div
            v-else
            class="row justify-content-end">
            <div class="col-auto">
                <AButton
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="state.isForm = !state.isForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
            </div>
        </div>
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div v-if="form.errors.any()" class="mt-2">
            <!-- <AAlert
                v-for="(item, index) in state.errors"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable
                @close="state.errors = []" /> -->
            <AAlert
                v-for="(item, index) in form.errors.all()"
                class="mb-2"
                type="error"
                :key="index"
                :message="item.message"
                banner
                closable />
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterLoyaltyUserPic from '@/components/filter/FilterLoyaltyUserPic'
import Form from 'vform'
import { pickBy } from 'lodash'

export default defineComponent({
    components: {
        FilterLoyaltyUserPic,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const formRef = ref();

        const form = reactive(new Form({
            user_id: [],
            program_id: props.item.id,
        }))

        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nopeg',
                    dataIndex: 'user_nopeg',
                },
                {
                    title: 'Nama User',
                    dataIndex: 'user_name',
                },
                {
                    title: 'Email',
                    dataIndex: 'user_email',
                },
                {
                    title: 'Role',
                    dataIndex: 'user_role_name',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: `/api/loyalty-program-user-pic`,
            errors: [],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                program: props.item.id,
                page: 1,
                "per-page": 10,
            },
            isForm: false,
            rules: {
                user_id: [
                    {
                        required: true,
                        message: "User pic tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });

            form.post(state.endpoint)
                .then(({ data }) => {
                    if (data === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }

                    message.success('Berhasil ditambahkan')
                    form.reset()
                    formRef.value.resetFields()
                    state.isForm = false
                    fetchDataList()
                })

        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isDelete = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`${state.endpoint}/${record.id}`)
                        .then(({ data }) => {
                            fetchDataList()
                            message.success('Berhasil di hapus!')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            formRef,
            form,
            errorMessage,
            handleModalCancel,
            handleTableChange,
            handleOk,
            btnDelete,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
